import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from '../../components/localizedLink'
import { GatsbyImage } from '@wardpeet/gatsby-image-nextgen/compat';
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Newsletter from '../../components/newsletter'
import Testimonial from '../../components/testimonial'
import Contattami from '../../components/contattami'
import {filterByLang} from '../../utility/utils'
import {FormattedMessage} from 'react-intl'

const CosaFaccio = ({ pageContext: { locale } }) => (
  <StaticQuery
    query={graphql`
      query {
        topimg: file(relativePath: { eq: "testate/cosa_faccio_header.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1240) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        intro: allContentfulContenutoGenerico(filter: {contentful_id: {eq: "1RX6ESsVIrCrMDEBreCe7u"}}) {
          edges {
            node {
              node_locale
              claim {
                childMarkdownRemark {
                  html
                }
              }
              testo {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        } 
        allContentfulTestimonianze(filter: {pagine: {eq: "Chi sono"}}) {
          edges {
            node {
              autore
              node_locale
              testo {
                testo
              }
              testo {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        box1: allContentfulContenutoGenerico(filter: {contentful_id: {eq: "58Q2xV6aFuhA3E0a9ZEU4X"}}) {
          edges {
            node {
              node_locale
              titolo
              testo {
                childMarkdownRemark {
                  html
                }
              }
              immagine {
                fluid(maxWidth: 600) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            } 
          }
        }
        box2:  allContentfulContenutoGenerico(filter: {contentful_id: {eq: "6yXZJZWnAwXUYcbNxrCs5t"}}) {
          edges {
            node {
              node_locale
              titolo
              testo {
                childMarkdownRemark {
                  html
                }
              }
              immagine {
                fluid(maxWidth: 600) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
        box3: allContentfulContenutoGenerico(filter: {contentful_id: {eq: "ZdTdSpj75lcQXt0L9RyTx"}}) {
          edges {
            node {
              titolo
              node_locale
              testo {
                childMarkdownRemark {
                  html
                }
              }
              immagine {
                fluid(maxWidth: 600) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const intro = filterByLang(data.intro,locale)[0];
      const box1 = filterByLang(data.box1,locale)[0];
      const box2 = filterByLang(data.box2,locale)[0];
      const box3 = filterByLang(data.box3,locale)[0];
      const testimonianze = filterByLang(data.allContentfulTestimonianze,locale).filter(item => {return item.testo.testo!=='no'}); 
      return (
      <Layout locale={locale}>
        <SEO title="cosaFaccio" />
        <div className="row">
          <div className="col-12 text-center">
            <FormattedMessage id="cosaFaccio">
                {txt => <h1 className="pagetitle">{txt}</h1>}
            </FormattedMessage>
          </div>
        </div>
        <div className="section section--top">
          <div className="row">
            <div className="col-12">
              <GatsbyImage className="section__topimg" fluid={data.topimg.childImageSharp.fluid} />
            </div>
          </div>
       </div>
       <div className="section">
          <div className="row">
            <div className="col-12 text-center">
              <div  className="section__incipit" dangerouslySetInnerHTML={{__html: intro.claim.childMarkdownRemark.html}} />
              {intro.testo &&
                <div className="section__text"  dangerouslySetInnerHTML={{__html: intro.testo.childMarkdownRemark.html}} />
              }
            </div>
          </div>
        </div>
        <div className="section">
          <div className="row">
            <div className="col-12 col-sm-4">
              {!!box1.immagine && 
                <Link className="imghover" to="/cosa-faccio/valorizzare-i-tuoi-prodotti/" title={box1.titolo}>
                  <GatsbyImage className="section__topimg" fluid={box1.immagine.fluid} />
                </Link>
              }
              <h2 className="section__subtitle">
              <Link to="/cosa-faccio/valorizzare-i-tuoi-prodotti/" title={box1.titolo}>
                {box1.titolo}
              </Link>
              </h2>
              <div  className="section__text" dangerouslySetInnerHTML={{__html: box1.testo.childMarkdownRemark.html}} />
            </div>
            <div className="col-12 col-sm-4">
              {!!box2.immagine && 
                <Link className="imghover" to="/cosa-faccio/ricette-disegnate/" title={box2.titolo}>
                  <GatsbyImage className="section__topimg" fluid={box2.immagine.fluid} />
                </Link>
              }
              <h2 className="section__subtitle">
                <Link to="/cosa-faccio/ricette-disegnate/" title={box2.titolo}>
                  {box2.titolo}
                </Link>
              </h2>
              <div  className="section__text" dangerouslySetInnerHTML={{__html: box2.testo.childMarkdownRemark.html}} />
            </div>
            <div className="col-12 col-sm-4">
              {!!box3.immagine && 
                <Link className="imghover" to="/cosa-faccio/illustrazioni-personalizzate/" title="realizzare una ricetta disegnata">
                  <GatsbyImage className="section__topimg" fluid={box3.immagine.fluid} />
                </Link>
              }
              <h2 className="section__subtitle">
                <Link to="/cosa-faccio/illustrazioni-personalizzate/" title="realizzare una ricetta disegnata">
                  {box3.titolo}
                </Link>
              </h2>
              <div  className="section__text" dangerouslySetInnerHTML={{__html: box3.testo.childMarkdownRemark.html}} />
            </div>
          </div>
        </div>

        <div className="section">  
          <div className="row">
            <div className="col-12 text-center">
              <FormattedMessage id="diconoDiMe">
                    {txt => <h2 className="section__title">{txt}</h2>}
                  </FormattedMessage>
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1">
              <Testimonial testimonianze={testimonianze}/>
            </div>
          </div>
        </div>

        <Contattami action="contattami2"/>

        <Newsletter/>
      </Layout>
    )
    }}
  />
 
)

export default CosaFaccio

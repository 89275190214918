
import React from 'react'
import Slider from "react-slick";
import { injectIntl } from 'react-intl'


const Testimonial = ({ intl: { locale }, testimonianze}) => {

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return(
        <Slider className="testimonial" {...settings}>
          {testimonianze.map( (item, i) => 
          <div key={i} className="testimonial__item">
            <div className="testimonial__text" dangerouslySetInnerHTML={{__html: item.testo.childMarkdownRemark.html}} />
            <div className="testimonial__author">
               {item.autore}
            </div>
          </div>)}
        </Slider>
  )}


export default injectIntl(Testimonial);